<template>
	<v-container fill-height grid-list-xl>
    <v-snackbar
      v-model="snackbar"
      :timeout="8000"
      top
      :color="color" dark
    >
      {{text}}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
		<v-row>
			<v-col>
		    <v-card >
		      <v-card-title primary-title>
		        Direcciones de envío <v-icon large right>airport_shuttle</v-icon> <v-spacer></v-spacer>
		        <v-btn color="primary" :to="{name: 'adddireccion'}">Agregar</v-btn>
		      </v-card-title>
		      <v-divider class="mb-1"></v-divider>
		      <v-card-text>
						<v-data-table
					    v-model="selected"
					    :headers="headers"
					    :items="getDirecciones"
					    single-select
					    item-key="name"
					    :mobile-breakpoint="100"
					    hide-default-footer
					    height="400"
					    fixed-header
              :loading="loading"
              loading-text="Cargando... Espere Por Favor"
					  >
              <template v-slot:item="{ item }">
                <tr v-on:dblclick="ver(item)">
                  <td >{{ item.nombre }}</td>
                  <td>{{ item.Calle }}</td>
                  <td>{{ item.colonia }}</td>
                  <td>{{ item.Ciudad }}</td>
                  <td>{{ item.encargado }}</td>
                  <td>{{ item.telefono }}</td>
                  <td><v-btn @click="eliminar(item.idweb)" icon><v-icon>delete</v-icon></v-btn></td>
                </tr>
              </template>
					  </v-data-table>
		      </v-card-text>
			  </v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      snackbar: false,
      color: 'success',
      text: '',
      loading: true,
      singleSelect: false,
      selected: [],
      headers: [
        { text: 'Nombre direción', align: 'left', sortable: false, value: 'nombre' },
        { text: 'Calle', value: 'Calle' },
        { text: 'Colonia', value: 'colonia' },
        { text: 'Ciudad', value: 'Ciudad' },
        { text: 'Encargado', value: 'encargado' },
        { text: 'Teléfono', value: 'telefono' },
        { text: 'Eliminar', value: 'actions' }
      ]
    }
  },

  created () {
    this.traerDirecciones(this.getdatosUsuario.numcli).then(response => {
      if (response == false) {
        this.loading = false
      } else {
        this.loading = false
      }
    })
  },

  methods: {
    ...mapActions('direccion', ['traerDirecciones', 'verDireccion', 'deleteDireccion']),

    ver (value) {
      this.verDireccion(value.idweb).then(response => {
        this.$router.push({ name: 'direccion' })
      })
    },

    eliminar (value) {
      this.deleteDireccion(value).then(response => {
        if (response == true) {
          this.color = 'success'
          this.text = 'Se elimino correctamente la dirección'
          this.snackbar = true
        } else {
          this.color = 'error'
          this.text = 'No se pudo eliminar la dirección'
          this.snack = true
        }
        this.traerDirecciones(this.getdatosUsuario.numcli)
      })
    }
  },

  computed: {
    ...mapGetters('direccion', ['getDirecciones']),
    ...mapGetters('Login', ['getdatosUsuario'])

  }

}
</script>
